<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="1" x2="0.241" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_food" transform="translate(-808 -2768)">
    <g data-name="Group 423" id="Group_423" transform="translate(-21 -3)">
     <rect :fill="background" data-name="Rectangle 214" height="183" id="Rectangle_214" transform="translate(829 2771)" width="183"/>
     <g data-name="Group 409" id="Group_409" transform="translate(882 2820)">
      <path d="M38.416,32.677H29.937V3H21.458V32.677H12.979V3H4.5V32.677A16.9,16.9,0,0,0,20.4,49.508V87.791H31V49.508A16.9,16.9,0,0,0,46.9,32.677V3H38.416Zm21.2-12.719V53.875h10.6V87.791h10.6V3C69.111,3,59.614,12.5,59.614,19.958Z" data-name="Icon material-restaurant" fill="url(#linear-gradient)" id="Icon_material-restaurant" transform="translate(-4.5 -3)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>